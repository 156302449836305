<template>
    <div class="history-record">
        <history-record-edit v-if="isEditable" ref="editor" :recordModel="recordModel"></history-record-edit>
        <history-record-display v-else :recordModel="recordModel"></history-record-display>
    </div>
</template>

<script>
import HistoryRecordDisplay from "@c/record-frame/history-record/history-record-display.vue"
import HistoryRecordEdit from "@c/record-frame/history-record/history-record-edit.vue"

export default {
    components: {
        HistoryRecordDisplay,
        HistoryRecordEdit
    },

    props: {
        listItemModel: Object,

    },

    data() {
        return {
            isEditable: undefined,
            recordModel: undefined,
            recordID: undefined
        }
    },

    mounted() {
        this.isEditable = (this.listItemModel.recordState == RECORD_STATUS.草稿 && !this.listItemModel.id)
        this.recordID = this.listItemModel.id

        if (this.recordID) {
            this.recordRequest()
        }
    },

    methods: {
        onBarButtonClick(title) {
            switch (title) {
                case "编辑":
                    this.isEditable = true
                    break;
                //暂存和发布只在编辑时出现，可用$refs
                case "暂存":
                    const saveParams = this.composeEditParams(RECORD_STATUS.草稿)
                    this.editRecord(saveParams)
                    break;
                case "发布":
                    if (!this.$refs.editor.title || !this.$refs.editor.editorRef.content || !this.$refs.editor.editorRef.signature) {
                        this.$message({
                            message: '内容填充不完全，请完善内容再发布记录',
                            offset: 2
                        });
                        return
                    }
                    const publishParams = this.composeEditParams(RECORD_STATUS.已发布)
                    this.editRecord(publishParams)
                    break;
                // 显示状态时出现
                case "撤销":
                    const cancelParams = this.composeEditParams(RECORD_STATUS.已撤销)
                    this.editRecord(cancelParams)
                    break;
                default:
                    break;
            }
        },

        composeEditParams(recordStatus) {
            switch (recordStatus) {
                //暂存和发布只在编辑时出现，可用$refs
                case RECORD_STATUS.草稿:
                case RECORD_STATUS.已发布:
                    return {
                        id: this.recordID,
                        recordState: recordStatus,
                        "patientId": this.listItemModel.patientId,
                        "title": this.$refs.editor.title,
                        "recordContent": this.$refs.editor.editorRef.content,
                        "autograph": this.$refs.editor.editorRef.signature
                    }
                case RECORD_STATUS.已撤销:
                    const params = { ...this.recordModel }
                    params.recordState = RECORD_STATUS.已撤销
                    return params
                default:
                    break;
            }
        },

        async recordRequest() {
            try {
                const params = {
                    id: this.recordID
                }
                this.recordModel = await this.$api.getHistoryRecord(params)
            } catch (error) {
                this.$toast.showRed(error)
            }
        },

        async editRecord(params) {
            try {
                //提交修改
                this.recordID = await this.$api.saveHistoryRecord(params)
                //请求新数据
                this.recordModel = await this.$api.getHistoryRecord({ id: this.recordID })
                //显示展示页面
                this.isEditable = false
                //刷新record-bar
                const newListItemModel = { ...this.listItemModel }
                newListItemModel.id = this.recordModel.id
                newListItemModel.recordState = this.recordModel.recordState
                this.$emit("listItemModelChanged", newListItemModel)
            } catch (error) {
                this.$toast.showRed(error)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.history-record {
    width: 100%;
    height: 969px;
    background-color: white;

}
</style>



