<template>
    <div class="history-record-display">
        <div class="history-record-display-top">
            <p class="history-record-display-top-title">{{ title }}</p>
        </div>
        <record-editor-display signatureTitle="签字" :content="content" :signature="signature" minHeight="244"></record-editor-display>
    </div>
</template>

<script>
import RecordEditorDisplay from "@c/record-frame/common/record-editor-display.vue";
export default {
    components: {
        RecordEditorDisplay
    },

    props: {
        recordModel: Object
    },

    computed: {
        title() {
            return placeholderText(this.recordModel?.title)
        },

        signature() {
            return placeholderText(this.recordModel?.autograph)
        },

        content() {
            return placeholderText(this.recordModel?.recordContent)
        }
    },
}
</script>

<style lang="scss" scoped>
.history-record-display {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-top {
        background-color: #F7F7F7;
        width: 900px;
        height: 99px;
        display: flex;
        align-items: center;
        margin-top: 11px;
        margin-bottom: 18px;

        &-title {
            font-size: 30px;
            font-weight: bolder;
            margin-left: 22px;
        }
    }
}
</style>