<template>
    <div class="history-record-edit">
        <div class="history-record-edit-container">
            <div class="history-record-edit-container-header">
                <record-input class="history-record-edit-container-header-input" title="标题" v-model="title"
                    width="376"></record-input>
            </div>
            <record-editor ref="editorRef" signatureTitle="签字" minHeight="700" :initContent="content"
                :initSignature="signature"></record-editor>
        </div>

    </div>
</template>

<script>
import RecordInput from "@c/record-frame/common/record-input.vue";
import RecordEditor from "@c/record-frame/common/record-editor.vue"
export default {
    components: {
        RecordInput,
        RecordEditor
    },

    props: {
        recordModel: Object
    },

    data() {
        return {
            editorRef: undefined,
            title: "",
            content: "",
            signature: "",
        }
    },

    mounted() {
        this.editorRef = this.$refs.editorRef
        if (this.recordModel) {
            this.title = this.recordModel.title
            this.content = this.recordModel.recordContent
            this.signature = this.recordModel.autograph
        }

    },

}
</script>

<style lang="scss" scoped>
.history-record-edit {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-container {
        width: 900px;

        &-header {
            background-color: #F7F7F7;
            height: 99px;
            display: flex;
            align-items: center;
            margin-top: 11px;
            margin-bottom: 18px;

            &-input{
                margin-left: 22px;
                margin-bottom: 0px;
            }


        }

    }

}
</style>
